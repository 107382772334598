import React, { useState } from "react";
import {
  TableRow,
  TableCell,
  IconButton,
  Table,
  TableHead,
  TableBody,
  Paper,
  Typography,
  Tooltip,
  Chip,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Box,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import Skeleton from "@material-ui/lab/Skeleton";
import { Bid, useAuctionCurrentBid, useLiveBid } from "../../services/AuctionService";
import { currencyFormat, dateToDateString } from "../../helpers/text-format/TextFormat";
import * as BidService from "../../services/BidService";
import * as Styles from "./styles/LiveBidItemDialogStyles";
import { LoadingComponent } from "../loading/LoadingComponent";
import { Link } from "react-router-dom";
import { useSnackbar } from "notistack";
import { suspendBidder } from "../../services/BidderService";
import DeleteIcon from "@material-ui/icons/Delete";
import { SuspensionStatusType } from "../../enums/SuspensionStatusType";

interface LiveBidDialogProps {
  auctionId: string;
  bids: Bid[] | null;
  bidsLoading: boolean;
  refreshBids: () => void;
  showProxyBids: boolean;
}

export const DialogBids: React.FC<LiveBidDialogProps> = ({ auctionId, bids, bidsLoading, refreshBids, showProxyBids }) => {
  const classes = Styles.LiveBidItemDialogStyles();

  const [isRemovingBid, setIsRemovingBid] = useState(false);
  const [currentBid, currentBidLoading, , refreshCurrentBid] = useAuctionCurrentBid(auctionId);
  const [liveBid, , , refreshLiveBid] = useLiveBid(auctionId);
  const [showReason, setShowReason] = useState(false);
  const [reason, setReason] = useState("");
  const [bidderId, setBidderId] = useState<string>("");
  const [performingAction, setPerformingAction] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const removeBid = (bidId: string) => {
    setIsRemovingBid(true);
    BidService.RemoveCurrentBid(auctionId, bidId)
      .then(() => {
        refreshBids();
        refreshCurrentBid();
        refreshLiveBid();
      })
      .finally(() => {
        setIsRemovingBid(false);
      });
  };

  const doSuspendBidder = async (bidderId: string) => {
    setPerformingAction(true);
    try {
      await suspendBidder(bidderId, SuspensionStatusType.Other, reason);
      setReason("");
      refreshBids();
      refreshCurrentBid();
      setShowReason(false);
    } catch {
      enqueueSnackbar("Suspending the bidder failed. Please try again.", { variant: "error" });
    } finally {
      setPerformingAction(false);
    }
  };

  const suspendBidderDialog = () => (
    <Dialog open={showReason} onClose={() => setShowReason(false)}>
      <DialogTitle id="form-dialog-title">Suspend Bidder</DialogTitle>
      <DialogContent>
        <DialogContentText>Please enter the reason why you are suspending this bidder</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Suspension Reason"
          value={reason}
          onChange={target => setReason(target.target.value)}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setShowReason(false)} color="primary" disabled={performingAction}>
          Cancel
        </Button>
        <Button onClick={() => doSuspendBidder(bidderId)} color="primary" disabled={performingAction}>
          Suspend Bidder
        </Button>
      </DialogActions>
    </Dialog>
  );

  const bidList = () => {
    if (bids && bids.length > 0) {
      return bids.map((bid: Bid, index: number) => (
        <TableRow key={index} className={classes.tableRow}>
          <TableCell align="left" size="small">
            {getBidderName(bid.bidderId, bid.bidderName, bid.suspended)}
          </TableCell>
          <TableCell align="left" size="small">
            {dateToDateString(bid.bidDateTime)}
          </TableCell>
          <TableCell align="left" size="small">
            <Box component="div" style={{ display: "flex", justifyContent: "center" }}>
              {isBuyBackChip(bid)}
              <Tooltip title="Current Bid">
                <Chip className={classes.defaultChip} size="small" color="default" label={currencyFormat(bid?.amount ?? 0)} />
              </Tooltip>
            </Box>
          </TableCell>
          <TableCell>
            {bid.suspended ? (
              <></>
            ) : (
              <Tooltip title="Suspend Bidder">
                <div>
                  <IconButton
                    color={"primary"}
                    onClick={() => {
                      setShowReason(true);
                      setBidderId(bid.bidderId);
                    }}
                    disabled={bidsLoading || currentBidLoading || bid.isBuyBack}
                  >
                    <CancelIcon />
                  </IconButton>
                </div>
              </Tooltip>
            )}
          </TableCell>
        </TableRow>
      ));
    } else {
      return (
        <TableRow>
          <TableCell colSpan={5} align="left" size="small">
            No bids have been placed
          </TableCell>
        </TableRow>
      );
    }
  };

  const headerCount = () => {
    if (bidsLoading) {
      return (
        <Typography variant="subtitle2" component="h1" gutterBottom>
          Bids: <Skeleton style={{ display: "inline-block" }} variant="text" width={25} />
        </Typography>
      );
    } else {
      return (
        <Typography variant="subtitle2" component="h1" gutterBottom>
          Bids: {bids ? bids.length : 0}
        </Typography>
      );
    }
  };

  const getBidderName = (bidderId: string, bidderName: string, suspended: boolean) => {
    if (suspended) {
      return (
        <Tooltip title={"This bidder has been suspended"}>
          <Link style={{ textDecoration: "none", color: "black" }} to={`/BidderDetailsChange/${bidderId}`}>
            <Chip className={classes.redChip} style={{ cursor: "pointer" }} size="small" color="default" label={bidderName} />
          </Link>
        </Tooltip>
      );
    } else {
      return (
        <Link style={{ textDecoration: "none", color: "black" }} to={`/BidderDetailsChange/${bidderId}`}>
          <>{bidderName}</>
        </Link>
      );
    }
  };

  const isBuyBackChip = (bid: Bid) => {
    if (bid.isBuyBack) {
      return (
        <Tooltip title="Buyback has been placed">
          <Chip className={classes.yellowChip} size="small" color="default" label={"BB"} />
        </Tooltip>
      );
    } else {
      return "";
    }
  };

  return (
    <>
      {isRemovingBid && <LoadingComponent label="Removing bid" />}
      {!isRemovingBid && (
        <>
          {suspendBidderDialog()}
          {bids && bids.length > 0 && (
            <Paper className={classes.list} style={{ marginBottom: "24px" }}>
              <Typography variant="subtitle2" component="h1" gutterBottom>
                Current Bid: {liveBid?.isProxy && showProxyBids && `(${currencyFormat(liveBid.proxyAmount)} proxy)`}
              </Typography>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Placed</TableCell>
                    <TableCell>Bid</TableCell>
                    <TableCell> {currentBid?.suspended ? <></> : <>Suspend Bidder</>}</TableCell>
                    <TableCell>Remove</TableCell>
                  </TableRow>
                </TableHead>
                {currentBidLoading && (
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <Skeleton variant="text" height={20} />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant="text" height={20} />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant="text" height={20} />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant="text" height={20} />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant="text" height={20} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
                {!currentBidLoading && currentBid && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="left" size="small" style={{ padding: "6px 6px 6px 16px" }}>
                        {getBidderName(currentBid.bidderId, currentBid.bidderName, currentBid.suspended)}
                      </TableCell>
                      <TableCell align="left" size="small">
                        {dateToDateString(currentBid.bidDateTime)}
                      </TableCell>
                      <TableCell align="left" size="small">
                        <Box component="div" style={{ display: "flex", justifyContent: "center" }}>
                          {isBuyBackChip(currentBid)}
                          <Tooltip title="Current Bid">
                            <Chip className={classes.defaultChip} size="small" color="default" label={currencyFormat(currentBid?.amount ?? 0)} />
                          </Tooltip>
                        </Box>
                      </TableCell>
                      <TableCell>
                        {currentBid.suspended ? (
                          <></>
                        ) : (
                          <Tooltip title="Suspend Bidder">
                            <div>
                              <IconButton
                                color={"primary"}
                                onClick={() => {
                                  setShowReason(true);
                                  setBidderId(currentBid.bidderId);
                                }}
                                disabled={bidsLoading || currentBidLoading || currentBid.isBuyBack}
                              >
                                <CancelIcon />
                              </IconButton>
                            </div>
                          </Tooltip>
                        )}
                      </TableCell>
                      <TableCell align="left" size="small">
                        <Tooltip title="Remove bid">
                          <div>
                            <IconButton
                              color={"primary"}
                              onClick={() => removeBid(currentBid.bidId)}
                              disabled={bidsLoading || currentBidLoading || currentBid.isBuyBack}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </div>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </Paper>
          )}
          <Paper className={classes.list}>
            {headerCount()}
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Placed</TableCell>
                  <TableCell>Bid</TableCell>
                  <TableCell>Suspend Bidder</TableCell>
                </TableRow>
              </TableHead>
              {bidsLoading && (
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Skeleton variant="text" height={20} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" height={20} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" height={20} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" height={20} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
              {!bidsLoading && <TableBody>{bidList()}</TableBody>}
            </Table>
          </Paper>
        </>
      )}
    </>
  );
};
