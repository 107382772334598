import * as React from "react";
import { useHistory } from "react-router-dom";
import { Button, Checkbox, CssBaseline, FormControlLabel, Grid, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import { useSnackbar, OptionsObject } from "notistack";
import { LoadingComponent } from "../loading/LoadingComponent";
import * as SettingsService from "../../services/SettingsService";
import { IValidation, ValidationDefault, ValidationErrorDefault } from "../../interfaces/IValidation";
import * as Styles from "./styles/AdminSettingsFormStyles";
import { IUpdateSettingsConfiguration, UpdateSettingsConfigurationDefault } from "../../interfaces/configuration/UpdateSettingsConfiguration";

const variantError: OptionsObject = { variant: "error" };

interface AdminSettingsFormProps {
  id: string | null;
} 

export const AdminSettingsFormComponent: React.FC<AdminSettingsFormProps> = ({ id }) => {
  const history = useHistory();
  const classes = Styles.AdminSettingsFormStyles();
  const { enqueueSnackbar } = useSnackbar();

  var apiError: boolean = false;

  const [loading, setLoading] = React.useState<boolean>(true);
  const [publishing, setPublishing] = React.useState(false);
  const [toLoad, setToLoad] = React.useState<number>(1);
  const [loaded, setLoaded] = React.useState<number>(0);
  const [settings, setSettings] = React.useState<IUpdateSettingsConfiguration>(UpdateSettingsConfigurationDefault);





  const [validation, setValidation] = React.useState<IValidation>(ValidationDefault);

  const incrementLoad = () => {
    let newTotal = loaded + 1

    setLoaded(newTotal);

    if (newTotal >= toLoad) {
      setLoading(false);
    }
  }

  React.useEffect(() => {

    SettingsService.GetAdminSettings()
      .then(result => {
        if (result.parsedBody !== undefined) {
          setSettings(result.parsedBody);
          incrementLoad();
        }
      })
      .catch(async response => {
        console.log("error searching:" + response);
      });
  }, []);

  const onFieldChange = (e: any, errorName: string, capitalTextField: boolean = false) => {
    var value: any;

    if (e.target.type === "checkbox") {
      value = e.target.checked;
    } else if (e.target.type === "number") {
      value = e.target.value === "" ? 0 : Math.floor(e.target.value);
    } else if (capitalTextField) {
      value = e.target.value.toUpperCase();
    } else {
      value = e.target.value;
    }

    setSettings({
      ...settings,
      [e.target.name]: value,
    });
    if (e.target.type === "text") {
      const cursor = e.target.selectionStart;
      const element = e.target;
      window.requestAnimationFrame(() => {
        element.selectionStart = cursor;
        element.selectionEnd = cursor;
      });
    }
  };

  const handleValidation = (validationObj) => {
    setValidation(ValidationDefault);

    let list: IValidation = {};
    Object.entries(validationObj)
      .forEach(([key, value]) => {
        let lowercasedName = (value as any).field.charAt(0).toLowerCase() + (value as any).field.slice(1);
        list[lowercasedName] = { field: (value as any).field, error: (value as any).error };
        setValidation(list);
      });
  };

  const isError = (field: string): boolean => {
    let check = validation[field] !== undefined && validation[field].field !== "";
    return check;
  };

  const ErrorMessage = (field: string): string => {
    return validation[field] !== undefined && validation[field].field !== "" ? validation[field].error : "";
  };

  const handleBack = () => {
    history.push(`/`);
  };

  const save = () => {
    let valid = true;

    if (valid) {
      setPublishing(true);

        SettingsService.UpdateAdminSettings(settings)
          .then(result => {
            history.push(`/`);
          })
          .catch(async response => {
            if (response.status == 400) {
              handleValidation(response.validation);
              apiError = true;
            }

            enqueueSnackbar("Could not update settings, please try again.", variantError);
            setPublishing(false);
          });

    }

  };



  return (
    <>
      <CssBaseline />
      {loading ? (
        <LoadingComponent label="Loading" />
      ) : (
        <div>
          <Grid container spacing={3}>
            

            <Grid item xs={4} sm={6}>
              <FormControlLabel
                value="showProxyBidIndicatorOnLiveBids"
                control={
                  <Checkbox
                    name="showProxyBidIndicatorOnLiveBids"
                    checked={settings.showProxyBidIndicatorOnLiveBids}
                    onChange={value => onFieldChange(value, "")}
                    value={settings.showProxyBidIndicatorOnLiveBids}
                  />
                }
                label=" Proxy Bid Indicator - Show On Live Bids"
                labelPlacement="end"
              />
            </Grid>
           
            <Grid item xs={4} sm={6}>
              <FormControlLabel
                value="showProxyBidIndicatorOnCurrentBids"
                control={
                  <Checkbox
                    name="showProxyBidIndicatorOnCurrentBids"
                    checked={settings.showProxyBidIndicatorOnCurrentBids}
                    onChange={value => onFieldChange(value, "")}
                    value={settings.showProxyBidIndicatorOnCurrentBids}
                  />
                }
                label=" Proxy Bid Indicator - Show On Current Bids"
                labelPlacement="end"
              />
            </Grid>


          </Grid>

          <div className={classes.buttons}>
            <Button variant="contained" className={classes.button} onClick={handleBack}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" className={classes.button} onClick={save}>
              Confirm
            </Button>
          </div>

        </div>

      )}
    </>
  );
};
