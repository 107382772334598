import * as React from "react";
import { Container, Typography, Grid, Button, Paper, Box } from "@material-ui/core";
import * as Styles from "./styles/HomepageStyles";
import { Route } from "react-router-dom";
import { SecureArea } from "../components/authentication/SecureArea";
import { MemberPermission } from "../services/MemberService";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../services/TelemetryService";

function Homepage() {
  const classes = Styles.HomepageStyles();

  return (
    <Container maxWidth={"lg"} fixed>
      <SecureArea memberPermission={MemberPermission.AccountAdmin}>
        <Typography variant="h4" color="primary" gutterBottom>
          Salvage Market Admin
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Welcome to the Salvage Admin Portal. Manage various aspects of the auction site based on your permissions.
        </Typography>
        <Box mt={4}>
          <Grid container direction="row" justify="space-evenly" spacing={4}>
            <SecureArea hideNotAuthorisedMessage={true} memberPermission={MemberPermission.BucketAdmin}>
              <Grid item md={4} sm={6} xs={12}>
                <Paper elevation={3} variant="outlined" className={classes.homepageModulePaper}>
                  <Typography variant="h5" color="primary" gutterBottom align="center" className={classes.homepageModuleTitle}>
                    Auction Mgt
                  </Typography>

                  <Route
                    render={({ history }) => (
                      <Button
                        fullWidth
                        onClick={() => {
                          history.push(`/AuctionManagement/Auction`);
                        }}
                      >
                        <Typography variant="subtitle1" color="textPrimary" gutterBottom>
                          Lot Management
                        </Typography>
                      </Button>
                    )}
                  />

                  <Route
                    render={({ history }) => (
                      <Button
                        fullWidth
                        onClick={() => {
                          history.push(`/Buckets`);
                        }}
                      >
                        <Typography variant="subtitle1" color="textPrimary" gutterBottom>
                          Bucket Management
                        </Typography>
                      </Button>
                    )}
                  />
                  <Route
                    render={({ history }) => (
                      <Button
                        fullWidth
                        onClick={() => {
                          history.push(`/PackImport`);
                        }}
                      >
                        <Typography variant="subtitle1" color="textPrimary" gutterBottom>
                          Pack Upload
                        </Typography>
                      </Button>
                    )}
                  />
                  <Route
                    render={({ history }) => (
                      <Button
                        fullWidth
                        onClick={() => {
                          history.push(`/ManageFeaturedAuctions`);
                        }}
                      >
                        <Typography variant="subtitle1" color="textPrimary" gutterBottom>
                          Featured Lot Management
                        </Typography>
                      </Button>
                    )}
                  />
                </Paper>
              </Grid>
            </SecureArea>

            <SecureArea hideNotAuthorisedMessage={true} memberPermission={MemberPermission.AccountAdmin}>
              <Grid item md={4} sm={6} xs={12}>
                <Paper elevation={3} variant="outlined" className={classes.homepageModulePaper}>
                  <Typography variant="h5" color="primary" gutterBottom align="center" className={classes.homepageModuleTitle}>
                    Bidder Mgt
                  </Typography>
                  <Route
                    render={({ history }) => (
                      <Button
                        fullWidth
                        onClick={() => {
                          history.push(`/BidderSearch`);
                        }}
                      >
                        <Typography variant="subtitle1" color="textPrimary" gutterBottom>
                          Bidder Search
                        </Typography>
                      </Button>
                    )}
                  />

                  <Route
                    render={({ history }) => (
                      <Button
                        fullWidth
                        onClick={() => {
                          history.push(`/IdVerification`);
                        }}
                      >
                        <Typography variant="subtitle1" color="textPrimary" gutterBottom>
                          ID Verification
                        </Typography>
                      </Button>
                    )}
                  />

                  {/* <SecureArea hideNotAuthorisedMessage={true} memberPermission={MemberPermission.SuperAdmin}>
                    <Route
                      render={({ history }) => (
                        <Button
                          fullWidth
                          onClick={() => {
                            history.push(`/Refunds`);
                          }}
                        >
                          <Typography variant="subtitle1" color="textPrimary" gutterBottom>
                            Refunds
                          </Typography>
                        </Button>
                      )}
                    />
                  </SecureArea> */}
                </Paper>
              </Grid>
            </SecureArea>
            <SecureArea hideNotAuthorisedMessage={true} memberPermission={MemberPermission.FullMember}>
              <Grid item md={4} sm={6} xs={12}>
                <Paper elevation={3} variant="outlined" className={classes.homepageModulePaper}>
                  <Typography variant="h5" color="primary" gutterBottom align="center" className={classes.homepageModuleTitle}>
                    Live Auctions
                  </Typography>
                  <Route
                    render={({ history }) => (
                      <Button
                        fullWidth
                        onClick={() => {
                          history.push(`/LiveBids`);
                        }}
                      >
                        <Typography variant="subtitle1" color="textPrimary" gutterBottom>
                          Manage Live Bids
                        </Typography>
                      </Button>
                    )}
                  />
                </Paper>
              </Grid>
            </SecureArea>
            <SecureArea hideNotAuthorisedMessage={true} memberPermission={MemberPermission.AccountAdmin}>
              <Grid item md={4} sm={6} xs={12}>
                <Paper elevation={3} variant="outlined" className={classes.homepageModulePaper}>
                  <Typography variant="h5" color="primary" gutterBottom align="center" className={classes.homepageModuleTitle}>
                    Reporting
                  </Typography>

                  <SecureArea hideNotAuthorisedMessage={true} memberPermission={MemberPermission.BucketAdmin}>
                    <Route
                      render={({ history }) => (
                        <Button
                          fullWidth
                          onClick={() => {
                            history.push(`/LiveLotDetailsReport`);
                          }}
                        >
                          <Typography variant="subtitle1" color="textPrimary" gutterBottom>
                            LIVE Lot Details Report
                          </Typography>
                        </Button>
                      )}
                    />
                  </SecureArea>

                  <SecureArea hideNotAuthorisedMessage={true} memberPermission={MemberPermission.BucketAdmin}>
                    <Route
                      render={({ history }) => (
                        <Button
                          fullWidth
                          onClick={() => {
                            history.push(`/ClosedAuctionReporting`);
                          }}
                        >
                          <Typography variant="subtitle1" color="textPrimary" gutterBottom>
                            Closed Auction Reporting
                          </Typography>
                        </Button>
                      )}
                    />
                    <Route
                      render={({ history }) => (
                        <Button
                          fullWidth
                          onClick={() => {
                            history.push(`/AuctionStatusReporting`);
                          }}
                        >
                          <Typography variant="subtitle1" color="textPrimary" gutterBottom>
                            Auction Status Reporting
                          </Typography>
                        </Button>
                      )}
                    />
                  </SecureArea>
                  <Route
                    render={({ history }) => (
                      <Button
                        fullWidth
                        onClick={() => {
                          history.push(`/BidderSignUpReporting`);
                        }}
                      >
                        <Typography variant="subtitle1" color="textPrimary" gutterBottom>
                          Bidder Sign Up Reporting
                        </Typography>
                      </Button>
                    )}
                  />
                  <Route
                    render={({ history }) => (
                      <Button
                        fullWidth
                        onClick={() => {
                          history.push(`/BidderMailingListReporting`);
                        }}
                      >
                        <Typography variant="subtitle1" color="textPrimary" gutterBottom>
                          Bidder Mailing list Reporting
                        </Typography>
                      </Button>
                    )}
                  />
                  <SecureArea hideNotAuthorisedMessage={true} memberPermission={MemberPermission.SuperAdmin}>
                    <Route
                      render={({ history }) => (
                        <Button
                          fullWidth
                          onClick={() => {
                            history.push(`/MarginShareReporting`);
                          }}
                        >
                          <Typography variant="subtitle1" color="textPrimary" gutterBottom>
                            Margin Share Reporting
                          </Typography>
                        </Button>
                      )}
                    />
                  </SecureArea>

                  <SecureArea hideNotAuthorisedMessage={true} memberPermission={MemberPermission.SuperAdmin}>
                    <Route
                      render={({ history }) => (
                        <Button
                          fullWidth
                          onClick={() => {
                            history.push(`/PaymentReconcilliationReporting`);
                          }}
                        >
                          <Typography variant="subtitle1" color="textPrimary" gutterBottom>
                            Payment Reconcilliation
                          </Typography>
                        </Button>
                      )}
                    />
                  </SecureArea>

                  <SecureArea hideNotAuthorisedMessage={true} memberPermission={MemberPermission.SuperAdmin}>
                    <Route
                      render={({ history }) => (
                        <Button
                          fullWidth
                          onClick={() => {
                            history.push(`/MaliciousBidderReport`);
                          }}
                        >
                          <Typography variant="subtitle1" color="textPrimary" gutterBottom>
                            Malicious Bidder Report
                          </Typography>
                        </Button>
                      )}
                    />
                  </SecureArea>
                </Paper>
              </Grid>
            </SecureArea>

            <Grid item md={4} sm={6} xs={12}>
              <Paper elevation={3} variant="outlined" className={classes.homepageModulePaper}>
                <Typography variant="h5" color="primary" gutterBottom align="center" className={classes.homepageModuleTitle}>
                  Member Mgt
                </Typography>
                <Route
                  render={({ history }) => (
                    <Button
                      fullWidth
                      onClick={() => {
                        history.push(`/MemberDetails`);
                      }}
                    >
                      <Typography variant="subtitle1" color="textPrimary" gutterBottom>
                        Member Details
                      </Typography>
                    </Button>
                  )}
                />
                <SecureArea hideNotAuthorisedMessage={true} memberPermission={MemberPermission.LiveAuctionAdmin}>
                  <Route
                    render={({ history }) => (
                      <Button
                        fullWidth
                        onClick={() => {
                          history.push(`/Permissions`);
                        }}
                      >
                        <Typography variant="subtitle1" color="textPrimary" gutterBottom>
                          Administrators & Permissions
                        </Typography>
                      </Button>
                    )}
                  />
                </SecureArea>
              </Paper>
            </Grid>


            <Grid item md={4} sm={6} xs={12}>
              <Paper elevation={3} variant="outlined" className={classes.homepageModulePaper}>
                <Typography variant="h5" color="primary" gutterBottom align="center" className={classes.homepageModuleTitle}>
                  Configuration
                </Typography>

                <SecureArea hideNotAuthorisedMessage={true} memberPermission={MemberPermission.LiveAuctionAdmin}>
                  <Route
                    render={({ history }) => (
                      <Button
                        fullWidth
                        onClick={() => {
                          history.push(`/Configuration/InsuranceCompanies`);
                        }}
                      >
                        <Typography variant="subtitle1" color="textPrimary" gutterBottom>
                          Clients
                        </Typography>
                      </Button>
                    )}
                  />
                </SecureArea>

                <SecureArea hideNotAuthorisedMessage={true} memberPermission={MemberPermission.SuperAdmin}>
                  <Route
                    render={({ history }) => (
                      <Button
                        fullWidth
                        onClick={() => {
                          history.push(`/Configuration/Admin`);
                        }}
                      >
                        <Typography variant="subtitle1" color="textPrimary" gutterBottom>
                          Admin Settings
                        </Typography>
                      </Button>
                    )}
                  />
                </SecureArea>


                <SecureArea hideNotAuthorisedMessage={true} memberPermission={MemberPermission.SuperAdmin}>
                  <Route
                    render={({ history }) => (
                      <Button
                        fullWidth
                        onClick={() => {
                          history.push(`/StripeProducts`);
                        }}
                      >
                        <Typography variant="subtitle1" color="textPrimary" gutterBottom>
                          Bidder Subscription Plans
                        </Typography>
                      </Button>
                    )}
                  />
                </SecureArea>



              </Paper>
            </Grid>
          </Grid>
        </Box>
      </SecureArea>
    </Container>
  );
}

export default withAITracking(reactPlugin, Homepage, "Homepage");
