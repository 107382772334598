import { Settings } from "./../Settings";
import { ISalvageClientSettings } from "../interfaces/ISalvageClientSettings";
import { ApiService, HttpResponse } from "./ApiService";
import { IUpdateSettingsConfiguration } from "../interfaces/configuration/UpdateSettingsConfiguration";

const ClientSettings: ISalvageClientSettings = GetClientSettings();

export function GetClientSettings(): ISalvageClientSettings {
  return Settings;
}

export async function GetAdminSettings() {
  const bidder = ApiService().Get<IUpdateSettingsConfiguration>(`${ClientSettings.UsersPrivateApiUrl}/settings/getAdminConfiguration`);
  return bidder;
}

export const UpdateAdminSettings = async (settings: IUpdateSettingsConfiguration): Promise<HttpResponse<void>> => {
  return await ApiService().Put<void>(ClientSettings.UsersPrivateApiUrl + `/settings/updateAdminConfiguration`, settings);
};

