import * as React from "react";
import {
  Container,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  Link,
} from "@material-ui/core";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import * as Styles from "./styles/BiddersAwaitingDeDupeStyles";
import * as Address from "../../interfaces/IAddress";
import { LoadingComponent } from "../loading/LoadingComponent";
import { useBiddersAwaitingVerification, approveBidder, suspendBidder } from "../../services/BidderService";
import { useSnackbar } from "notistack";
import { IBiddersWithPotentialDuplicates } from "../../interfaces/bidders/IBiddersWithPotentialDuplicates";
import { BidderStatusDescription, BidderStatus } from "../../enums/BidderStatus";
import CheckIcon from "@material-ui/icons/Check";
import BlockIcon from "@material-ui/icons/Block";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { SuspensionStatusType } from "../../enums/SuspensionStatusType";

export const BiddersAwaitingDeDupe: React.FC = () => {
  const classes = Styles.BiddersAwaitingDeDupeStyles();
  const { enqueueSnackbar } = useSnackbar();
  const appInsights = useAppInsightsContext();

  const [loadedBidders, loadingBidders] = useBiddersAwaitingVerification(() =>
    enqueueSnackbar("A problem occurred retrieving the bidders", { variant: "error" })
  );
  const [bidders, setBidders] = React.useState<IBiddersWithPotentialDuplicates[]>([]);
  const [selectedDuplicates, setSelectedDuplicates] = React.useState<IBiddersWithPotentialDuplicates | undefined>(undefined);
  const [updatingBidderSuspend, setUpdatingBidderSuspend] = React.useState(false);
  const [updatingBidderApproved, setUpdatingBidderApproved] = React.useState(false);

  React.useEffect(() => {
    if (loadedBidders) {
      setBidders(loadedBidders);
    }
  }, [loadedBidders, loadingBidders]);

  const approveSelectedBidder = () => {
    if (selectedDuplicates) {
      setUpdatingBidderApproved(true);
      approveBidder(selectedDuplicates!.bidder.id, "This bidder is not a duplicate")
        .then(() => {
          enqueueSnackbar(`${selectedDuplicates.bidder.firstName} ${selectedDuplicates.bidder.lastName} has been approved`, { variant: "success" });
          setBidders(bidders!.filter(p => p.bidder.id !== selectedDuplicates.bidder.id));
          setSelectedDuplicates(undefined);
          setUpdatingBidderApproved(false);
        })
        .catch(ex => {
          appInsights.trackException({
            exception: new Error(
              `An error occurred whilst trying to approve ${selectedDuplicates.bidder.firstName} ${selectedDuplicates.bidder.lastName}`
            ),
            severityLevel: SeverityLevel.Error,
            properties: { component: "BidderAwaitingDeDupe", method: "approveSelectedBidder", ex },
          });
          enqueueSnackbar(`An error occurred whilst trying to approve ${selectedDuplicates.bidder.firstName} ${selectedDuplicates.bidder.lastName}`, {
            variant: "error",
          });
        });
    }
  };

  const suspendSelectedBidder = () => {
    if (selectedDuplicates) {
      setUpdatingBidderSuspend(true);
      suspendBidder(selectedDuplicates!.bidder.id, SuspensionStatusType.RegistrationIssue, "Duplicate bidder account has been detected")
        .then(() => {
          enqueueSnackbar(`${selectedDuplicates.bidder.firstName} ${selectedDuplicates.bidder.lastName} has been suspended`, { variant: "success" });
          setBidders(bidders!.filter(p => p.bidder.id !== selectedDuplicates.bidder.id));
          setSelectedDuplicates(undefined);
          setUpdatingBidderSuspend(false);
        })
        .catch(ex => {
          appInsights.trackException({
            exception: new Error(
              `An error occurred whilst trying to approve ${selectedDuplicates.bidder.firstName} ${selectedDuplicates.bidder.lastName}`
            ),
            severityLevel: SeverityLevel.Error,
            properties: { component: "BidderAwaitingDeDupe", method: "suspendSelectedBidder", ex },
          });
          enqueueSnackbar(`An error occurred whilst trying to approve ${selectedDuplicates.bidder.firstName} ${selectedDuplicates.bidder.lastName}`, {
            variant: "error",
          });
        });
    }
  };
  return (
    <Container className={classes.mainContainer}>
      {loadingBidders || !bidders ? (
        <LoadingComponent label="Checking for bidders that require duplication account checks" />
      ) : (
        <>
          {bidders.length > 0 ? (
            <>
              <Typography variant="h5">Bidders requiring duplication checks: {bidders.length}</Typography>
              <Table aria-label="Awaiting approval">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell align="left">Email</TableCell>
                    <TableCell align="left">Address</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {bidders.map(row => (
                    <TableRow key={row.bidder.id} onClick={() => setSelectedDuplicates(row)} className={classes.duplicateRows}>
                      <TableCell scope="left">
                        {row.bidder.firstName} {row.bidder.lastName}
                      </TableCell>
                      <TableCell align="left">{row.bidder.email}</TableCell>
                      <TableCell align="left">{Address.formatAddressAsSingleLine(row.bidder.address)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </>
          ) : (
            <Typography variant="h5">You have no potential duplicate bidders to check</Typography>
          )}
        </>
      )}
      {selectedDuplicates && (
        <Dialog
          fullWidth={true}
          maxWidth={false}
          onClose={() => setSelectedDuplicates(undefined)}
          aria-labelledby="customized-dialog-title"
          open={selectedDuplicates !== undefined}
        >
          <DialogTitle id="customized-dialog-title">
            Approval for {selectedDuplicates!.bidder.firstName} {selectedDuplicates!.bidder.lastName} ({selectedDuplicates.bidder.email})
            <Typography>{Address.formatAddressAsSingleLine(selectedDuplicates!.bidder.address)}</Typography>
          </DialogTitle>
          <DialogContent dividers>
            <Typography variant="h6">Similar Bidders:</Typography>
            <Table stickyHeader size="small" aria-label="Awaiting approval">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell align="left">Status</TableCell>
                  <TableCell align="left">Email</TableCell>
                  <TableCell align="left">Address</TableCell>
                  <TableCell align="left">Registration IP</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedDuplicates.potentialDuplicates.map(duplicate => (
                  <TableRow
                    key={duplicate.id}
                    className={duplicate.bidderStatus === BidderStatus.Suspended ? classes.suspendedBidderRow : classes.otherBidderRow}
                    onClick={() => { }} // Make this open the bidder somehow
                  >
                    <TableCell scope="left">
                      <Link target="_blank" href={`BidderDetailsChange/${duplicate.id}`}>
                        {duplicate.firstName} {duplicate.lastName}
                      </Link>
                    </TableCell>
                    <TableCell scope="left">{BidderStatusDescription(duplicate.bidderStatus)}</TableCell>
                    <TableCell align="left">{duplicate.email}</TableCell>
                    <TableCell align="left">{Address.formatAddressAsSingleLine(duplicate.address)}</TableCell>
                    <TableCell align="left">{duplicate.registrationIP}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </DialogContent>
          <DialogActions>
            <div className={classes.wrapper}>
              <Button
                disabled={updatingBidderSuspend || updatingBidderApproved}
                autoFocus
                onClick={suspendSelectedBidder}
                variant="outlined"
                endIcon={<BlockIcon />}
              >
                Suspend Bidder
              </Button>
              {updatingBidderSuspend && <CircularProgress size={24} className={classes.buttonProgress} />}
            </div>
            <div className={classes.wrapper}>
              <Button
                disabled={updatingBidderSuspend || updatingBidderApproved}
                autoFocus
                onClick={approveSelectedBidder}
                variant="outlined"
                color="primary"
                endIcon={<CheckIcon />}
              >
                Approve Bidder
              </Button>
              {updatingBidderApproved && <CircularProgress size={24} className={classes.buttonProgress} />}
            </div>
          </DialogActions>
        </Dialog>
      )}
    </Container>
  );
};
